document.addEventListener("DOMContentLoaded", () => {
  init();
});

/* INIT */

function init() {
  const anchors = document.querySelectorAll('a[href*="#"]');

  for (let anchor of anchors) {
    anchor.addEventListener("click", function (e) {
      e.preventDefault();

      const blockID = anchor.getAttribute("href").substr(1);

      document.getElementById(blockID).scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    });
  }

  const swiper = new Swiper(".main-slider", {
    loop: true,
    speed: 600,
    spaceBetween: 100,
    autoplay: {
      delay: 10000,
    },

    mousewheel: {
      releaseOnEdges: true,
      forceToAxis: true,
    },

    // Navigation arrows
    navigation: {
      nextEl: ".main-section__navigation-item_next",
      prevEl: ".main-section__navigation-item_prev",
    },
  });

  //pagination capsule

  let windowWidth = $(window).width();
  $(window).on("resize", function () {
    windowWidth = $(window).width();

    swiperCapsule(windowWidth);
    swiperCapsule2024(windowWidth);
  });

  function swiperCapsule(windowWidth) {
    const swiperCapsule = new Swiper(".capsule__slider", {
      speed: 600,
      spaceBetween: 33,
      slidesPerView: 4,

      /*       mousewheel: {
              releaseOnEdges: true,
              forceToAxis: true
            }, */

      breakpoints: {
        1000: {
          slidesPerView: 4,
        },

        700: {
          slidesPerView: 3,
        },

        475: {
          spaceBetween: 67,
          slidesPerView: 2,
        },

        0: {
          slidesPerView: 1,
        },
      },

      scrollbar: {
        el: ".capsule__pagination-progressbar",
        draggable: true,
        hide: false,
        snapOnRelease: false,
        dragSize: 30,
      },

      on: {
        init: function () {
          if (windowWidth > 1000) {
            $(".capsule__pagination-number-slide").text(
              `${this.realIndex + 1}-${this.realIndex + 4} / ${
                this.slides.length
              }`
            );
          } else if (windowWidth > 700) {
            $(".capsule__pagination-number-slide").text(
              `${this.realIndex + 1}-${this.realIndex + 3} / ${
                this.slides.length
              }`
            );
          } else if (windowWidth > 475) {
            $(".capsule__pagination-number-slide").text(
              `${this.realIndex + 1}-${this.realIndex + 2} / ${
                this.slides.length
              }`
            );
          } else if (windowWidth <= 475) {
            $(".capsule__pagination-number-slide").text(
              `${this.realIndex + 1} / ${this.slides.length}`
            );
          }
        }, // do also on init
        slideChange: function () {
          if (windowWidth > 1000) {
            $(".capsule__pagination-number-slide").text(
              `${this.realIndex + 1}-${this.realIndex + 4} / ${
                this.slides.length
              }`
            );
          } else if (windowWidth > 700) {
            $(".capsule__pagination-number-slide").text(
              `${this.realIndex + 1}-${this.realIndex + 3} / ${
                this.slides.length
              }`
            );
          } else if (windowWidth > 475) {
            $(".capsule__pagination-number-slide").text(
              `${this.realIndex + 1}-${this.realIndex + 2} / ${
                this.slides.length
              }`
            );
          } else if (windowWidth <= 475) {
            $(".capsule__pagination-number-slide").text(
              `${this.realIndex + 1} / ${this.slides.length}`
            );
          }
        },
      },
    });
  }

  swiperCapsule(windowWidth);

  function swiperCapsule2024(windowWidth) {
    const swiperCapsule2024 = new Swiper(".capsule__slider-2024", {
      speed: 600,
      spaceBetween: 33,
      slidesPerView: 4,

      /*       mousewheel: {
            releaseOnEdges: true,
            forceToAxis: true
          }, */

      breakpoints: {
        1000: {
          slidesPerView: 4,
        },

        700: {
          slidesPerView: 3,
        },

        475: {
          spaceBetween: 67,
          slidesPerView: 2,
        },

        0: {
          slidesPerView: 1,
        },
      },

      scrollbar: {
        el: ".capsule__pagination-progressbar-2024",
        draggable: true,
        hide: false,
        snapOnRelease: false,
        dragSize: 30,
      },

      on: {
        init: function () {
          if (windowWidth > 1000) {
            $(".capsule__pagination-number-slide-2024").text(
              `${this.realIndex + 1}-${this.realIndex + 4} / ${
                this.slides.length
              }`
            );
          } else if (windowWidth > 700) {
            $(".capsule__pagination-number-slide-2024").text(
              `${this.realIndex + 1}-${this.realIndex + 3} / ${
                this.slides.length
              }`
            );
          } else if (windowWidth > 475) {
            $(".capsule__pagination-number-slide-2024").text(
              `${this.realIndex + 1}-${this.realIndex + 2} / ${
                this.slides.length
              }`
            );
          } else if (windowWidth <= 475) {
            $(".capsule__pagination-number-slide-2024").text(
              `${this.realIndex + 1} / ${this.slides.length}`
            );
          }
        }, // do also on init
        slideChange: function () {
          if (windowWidth > 1000) {
            $(".capsule__pagination-number-slide-2024").text(
              `${this.realIndex + 1}-${this.realIndex + 4} / ${
                this.slides.length
              }`
            );
          } else if (windowWidth > 700) {
            $(".capsule__pagination-number-slide-2024").text(
              `${this.realIndex + 1}-${this.realIndex + 3} / ${
                this.slides.length
              }`
            );
          } else if (windowWidth > 475) {
            $(".capsule__pagination-number-slide-2024").text(
              `${this.realIndex + 1}-${this.realIndex + 2} / ${
                this.slides.length
              }`
            );
          } else if (windowWidth <= 475) {
            $(".capsule__pagination-number-slide-2024").text(
              `${this.realIndex + 1} / ${this.slides.length}`
            );
          }
        },
      },
    });
  }

  swiperCapsule2024(windowWidth);
}

$(document).ready(function () {
  $("html").removeClass("js-block-scroll");
  $("body").addClass("js-page-ready");
});

(function ($) {
  $.fn.pageAnimate = function (option) {
    var block = $(this),
      pageScrollTop = $(window).scrollTop(),
      windowHeight = $(window).height(),
      pageBottom = pageScrollTop + windowHeight,
      option = block.extend(
        {
          backlash: windowHeight / 3,
        },
        option
      );

    saveTitle = $("title").text();

    $(window).on(
      "DOMMouseScroll mousewheel scroll touchmove scrollend resize load",
      function () {
        updateScroll();
      }
    );

    function updateScroll() {
      (pageScrollTop = $(window).scrollTop()),
        (windowHeight = $(window).height()),
        (pageBottom = pageScrollTop + windowHeight);

      block.each(function (i, element) {
        isElementInView($(this));
      });
    }

    function isElementInView(element) {
      var $element = $(element),
        elementHeight = $element.outerHeight(),
        elementTop = $element.offset().top; /*,
				elementBottom = elementTop + elementHeight*/

      if (
        pageBottom >= elementTop + option.backlash &&
        pageScrollTop <= elementTop + elementHeight - option.backlash / 2
      ) {
        $element.addClass("js-animate");

        if ($element.data("name")) {
          sectionInfo["name"] = $element.data("name");
        }

        if ($element.data("link")) {
          sectionInfo["link"] = $element.data("link");

          // set nav active
          var whoLink = $(
            '.top-nav__link[href="#' + $element.data("link") + '"]'
          );

          if (whoLink.length > 0) {
            whoLink
              .parents(".top-nav__item")
              .addClass("js-active")
              .siblings()
              .removeClass("js-active");
          } else {
            $(".top-nav__item").removeClass("js-active");
          }
        }

        if (
          $element.find(".js-title-animate").not(".js-show-recruitment")
            .length > 0
        ) {
          $element
            .find(".js-title-animate")
            .not(".js-show-recruitment")
            .generateText();
        }
      }
    }
  };
})(jQuery);
